/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.roboto {
  font-family: "Roboto", sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --primaryHotel: #19548b;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e3e9ef !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:focus {
  outline: none !important;
}

.flex-50 {
  flex: 0 0 50%;
}

.max-1400 {
  max-width: 1400px !important;
  margin: 0 auto !important;
}
.hotel__tab .react-tabs__tab--selected {
  background-color: white !important;
  color: var(--primaryHotel);
  border-color: #e6e6f2 #e6e6f2 #fff;
}

.hotel-tab-b-color {
  border-color: #e6e6f2 #e6e6f2 #fff;
}

.nr_form_control {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 1.42857143;
  color: #000123;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d2d2e4;
  border-radius: 2px;
  padding: 0.375rem 0.75rem;
}

.nr_form_control::placeholder{
  color: #71748d;
}

.hotelCar .carousel-inner {
  height: 100%;
}
.no-underline {
  text-decoration: none !important;
}

.has-hover {
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.has-hover:hover {
  transform: translateY(-0.5rem);
  -webkit-box-shadow: 0 5px 15px 0 rgb(61 70 79 / 15%);
  box-shadow: 0 5px 15px 0 rgb(61 70 79 / 15%);
  -webkit-transform: translateY(-0.5rem);
  -moz-transform: translateY(-0.5rem);
  -ms-transform: translateY(-0.5rem);
  -o-transform: translateY(-0.5rem);
}

.activeImageCheck svg {
  position: absolute;
  right: -12px;
  top: -12px;
  display: inline-block !important;
}

.deleteModalImage {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.hasDeleteHover:hover .deleteModalImage {
  transform: translateY(-28px);
  -webkit-transform: translateY(-28px);
  -moz-transform: translateY(-28px);
  -ms-transform: translateY(-28px);
  -o-transform: translateY(-28px);
}

.carouselModalHeight{
  max-height: 30rem;
}

.lighboxContainer{
  transform: translateY(-216px);
  z-index: 999;
  -webkit-transform: translateY(-216px);
  -moz-transform: translateY(-216px);
  -ms-transform: translateY(-216px);
  -o-transform: translateY(-216px);
}

.lineClamp-2{
  overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}

.bannerImageModal .modal-content{
  overflow: hidden;
}
.lighboxContainer{
  transition: all 0.5s;
  transform: translateY(0px);
}
.lightBoxUp{
  transform: translateY(-200px);
}


button:disabled{
  opacity: 0.5;
}